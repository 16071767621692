<!-- 电费 -->
<template>
  <div
    id="commonPay"
  >
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          :title="temp.projectName"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div

      class="outboxstyle"
    >
      <div class="boxstyle">
        <van-form :model="diyTemp" @submit="onSubmit">
          <div v-if="diyFormShowFlag" class="paymonry">{{ $t('commonPay.payTotal') }}</div>
          <van-row v-for="item in diyFormList" :key="item.key" style="border-bottom: 0.02667rem solid #ebedf0;">
            <van-col>
              <van-field v-if="item.type==='input'" v-model="diyTemp[item.key]" :required="item.pros== null? false:true" :placeholder="item.placeholder" :readonly="item.readonly" :name="item.comName" :label="item.label" :rules="item.pros== null? [{ required: false }] :[{ required: true, message: $t('main.inputMustWrite') }]" />
              <van-field v-if="item.type==='switch'" :required="item.pros== null? false:true" :name="item.comName" :label="item.label" :rules=" item.pros== null? [{ required: false }] :[{ required: true, message: $t('main.inputMustWrite') }]">
                <template #input>
                  <van-radio-group v-if="item.type==='switch'" v-model="diyTemp[item.key]" direction="horizontal">
                    <van-radio v-for="o in statusOptions" :key="o.key" :name="o.key">{{ o.value }}</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-else-if="item.type==='select'"
                readonly
                clickable
                :label="item.label"
                :value="diyTemp[item.key]"
                :placeholder="item.placeholder"
                :required="item.pros== null? false:true"
                :rules=" item.pros== null? [{ required: false }] :[{ required: true, message: $t('main.inputMustWrite') }]"
                @click="pickerClick(item)"
              />
              <van-popup v-model="item.showPicker" round position="bottom">
                <van-picker
                  show-toolbar
                  :columns="item.optionsDate"
                  @cancel="item.showPicker = false"
                  @confirm="onConfirm"
                />
              </van-popup>
            </van-col>
          </van-row>
          <div class="paymonry">{{ $t('commonPay.title1') }}</div>
          <van-cell v-if="temp.amtFlag === 'YES'" class="red">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">{{ $t('commonPay.payAmount') }}</span>
            </template>
            <span style="line-height:43px">{{ temp.payamtStr }}</span>
          </van-cell>
          <!-- <van-cell-group class="red">
            <van-field />
          </van-cell-group> -->
          <van-field
            v-if="temp.amtFlag === 'NO'"
            v-model="temp.payamtStr"
            class="red"
            :label="$t('commonPay.customAmount')"
            :name="temp.comName"
            required
            :rules="[{ required: true, message: $t('main.inputMustWrite') }]"
            type="number"
            input-align="right"
            @change="getRebateAmountNow()"
          />
          <van-cell v-if="temp.allowRebate === 'YES'" class="red">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">{{ $t('commonPay.discountAmount') }}</span>
            </template>
            <span style="line-height:43px">{{ fenToYuan(temp.rebateAmount) }}</span>
          </van-cell>
          <van-cell class="red">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">{{ $t('commonPay.payActual') }}</span>
            </template>
            <span style="line-height:43px">{{ formatterActualAmount() }}</span>
          </van-cell>
          <van-field
            v-if="temp.allowBatch === 'YES' && temp.amtFlag === 'YES'"
            v-model="temp.count"
            class="red"
            label="购买数量"
            required
            type="number"
            input-align="right"
            @input="buyMaxCount()"
          />
          <van-cell class="red">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">{{ $t('commonPay.payTotal') }}</span>
            </template>
            <span style="line-height:43px">{{ formatterSumAmount() }}</span>
          </van-cell>
          <div class="bottom">
            <van-button type="info" size="large" class="bottombutton" native-type="submit" :loading="btnNextLoading">{{ $t('main.next') }}</van-button>
          </div>
        </van-form>
        <!-- <div class="lastbattry">剩余电量:<span>--</span></div> -->
      </div>
      <!-- <img :src="imageL[0]" style="width: 87%;left: 6.5%;position: absolute;"> -->
      <!-- <div class="boxstylemoney">
        <van-form @submit="onSubmitForm"> -->
      <!-- </van-form>
      </div> -->
    </div>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getProjectVoById
} from '@/api/projectApply'
import { createOrder, getRebateAmount
} from '@/api/meeting'
import { createCommonOrder
} from '@/api/common'
import { getProjectIsAllowPayment } from '@/api/project'
import { pageListReiForm } from '@/api/reiform'
import { fenToYuan } from '@/utils/commonUtils'
import moment from 'moment/moment'
import Vue from 'vue'
import * as math from 'mathjs'
Vue.prototype.$math = math
// import ReiTabbar from '../components/ReiTabbar.vue'
export default {
  name: 'CommonPay',
  components: {
    // ReiTabbar
  },
  data() {
    return {
      // 输入金额合理性判断
      valuePass: true,
      readyShow: false,
      dataFormShowFlag: true,
      diyFormShowFlag: false,
      btn: {
        handleLast: false,
        handNext: false
      },
      imgStaticUrl: '../../../pay_img/img_*.png',
      imageUrlIndex: 1,
      dialogPreviewVisible: false,
      saveLoading: false,
      projectTypeTreeList: [],
      formBodyStyle: {
        padding: '10px',
        margin: '10px',
        height: '350px',
        overflow: 'auto'
      },
      maxCount: 1,
      configTemp: {
        type: '',
        name: '',
        placeholder: '',
        isNotNull: 'NO',
        options: []
      },
      rebateAmountVO: {
        payAmount: '',
        projectId: ''
      },
      dialogFormVisible: false,
      diyFormList: [],
      diyRules: {},
      messageCode: '',
      message: '',
      order: {
      },
      rules: {
        payamtStr: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: this.validatePayAmt }
        ]
      },
      btnNextLoading: false,
      statusOptions: [
        { key: 'YES', value: '是' },
        { key: 'NO', value: '否' }
      ],
      btnIsDisabled: false,
      list: [],
      total: 0,
      showFirstClass: true,
      showSecondClass: false,
      diyTemp: {
      },
      user: {
        name: '',
        idserial: ''
      },
      temp: {
        projectName: '',
        engName: '',
        amtFlag: 'YES', // 固定金额是否
        authentication: 'YES',
        description: '',
        payamt: '',
        payAmount: 0,
        proModelUrl: '',
        rebateAmount: 0,
        billAmountStr: '',
        proTypeInfoId: null,
        imgFile: null,
        count: 1,
        sumAmount: 0,
        maxCount: 1,
        imgUrl: '',
        allowRebate: '',
        formAssemblyVoList: [],
        payUserAssemblyDataVO: [],
        payLimit: '',
        payLimitStr: '',
        actualPayAmount: 0,
        schoolCode: window.localStorage.getItem('schoolcode')
      },
      // eslint-disable-next-line vue/no-dupe-keys
      list1: [
      ],
      money: '',
      value: '',
      value2: '',
      value3: '',
      value4: '',
      showPicker: false,
      conheight: {
        height: '',
        width: '100%',
        overflow: 'auto',
        // background: "#EFF6FF"
        background: 'transparent'
      },
      imageL: [
        require('../../assets/newImages/line.png')
      ],
      pickerList: {}
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  created() {
  },
  mounted() {
    getProjectVoById(this.$route.query.projectId).then((response) => {
      this.readyShow = true
      // if (this.temp.authentication === 'YES' && this.user.name === '') {
      //   this.$router.push({ name: 'login' })
      //   console.log('登出')
      //   return false
      // }
      this.temp = response.data
      if (this.temp.allowBatch === 'YES' && this.temp.amtFlag === 'YES') {
        this.temp.count = 1
      }
      window.localStorage.setItem('schoolcode', this.temp.schoolCode)
      // var str = this.temp.imgUrl
      // this.imageUrlIndex = str.replace(/[^0-9]/ig, '')
      if (response.data.formAssemblyVoList.length !== 0) {
        // 无自定义表单   单列显示
        this.dataFormShowFlag = false
        this.diyFormShowFlag = true
      }
      // 遍历 自定义表单内容
      this.temp.formAssemblyVoList.forEach(item => {
        var obj = {}
        obj.id = item.id
        obj.type = item.assemblyType
        obj.placeholder = item.tipsInfo
        obj.pros = item.mustStatus
        obj.label = item.showName
        obj.key = item.assemblyKey
        if (obj.type === 'input') {
          obj.comName = 'input'
          this.$set(this.diyRules, obj.key, [{ required: true, message: '必填项不能为空', trigger: 'blur' }])
        } else if (obj.type === 'select') {
          obj.showPicker = false
          obj.options = []
          this.$set(this.diyRules, obj.key, [{ required: true, message: '必填项不能为空', trigger: 'change' }])
          item.listAssemblyDetail.forEach(op => {
            var optionObj = {}
            optionObj.id = op.id
            optionObj.key = op.detailValue
            optionObj.label = op.showDetail
            optionObj.value = op.detailValue
            obj.options.push(optionObj)
          })
          obj.optionsDate = []
          obj.optionsDate = obj.options.map(v => v.label)
        } else if (obj.type === 'switch') {
          obj.comName = 'radio'
          this.$set(this.diyRules, obj.key, [{ required: true, message: '请选择', trigger: 'blur' }])
        }
        this.diyFormList.push(obj)
        // console.log(this.diyFormList)
      })
    })
  },
  methods: {
    createOrderNoFormByModelUrl() {
      // console.log('执行了NoForm')
      if (this.temp.proModelUrl === 'meeting') {
        this.handlerCreateOrderNoForm()
      } else if (this.temp.proModelUrl === 'common') {
        this.handlerCreateOrderNoFormCommon()
      }
    },
    createOrderByModelUrl() {
      // console.log('执行了Form')
      if (this.temp.proModelUrl === 'meeting') {
        this.handlerCreateOrder()
      } else if (this.temp.proModelUrl === 'common') {
        this.handlerCreateOrderCommon()
      }
    },
    // 缴费金额验证
    validatePayAmt(rule, value, callback) {
      if (!/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(value)) {
        this.btnLoading = false
        // 不通过
        this.valuePass = false
        callback(new Error('请输入正确格式的金额'))
      } else if (value <= 0) {
        // 不通过
        this.valuePass = false
        callback(new Error('输入金额必须大于0'))
      } else {
        // 不通过
        this.valuePass = true
        callback()
      }
    },
    formatterActualAmount() {
      // isNaN(temp.actualPayAmount = (temp.payamtStr - fenToYuan(temp.rebateAmount)))?0:(temp.actualPayAmount = temp.payamtStr - fenToYuan(temp.rebateAmount))
      if (this.valuePass === false) {
        return 0
      } else {
        if (isNaN(this.temp.payamtStr) || this.temp.payamtStr === '') {
          return 0
        } else if (this.temp.amtFlag === 'NO') {
          const amount = this.fenToYuan(this.temp.rebateAmount)
          this.temp.actualPayAmount = this.temp.payamtStr - amount
          // this.temp.actualPayAmount = math.format(math.chain(math.bignumber(this.temp.payamtStr)).subtract(math.bignumber(fenToYuan(this.temp.rebateAmount))).done())
          return this.temp.actualPayAmount
        } else if (this.temp.amtFlag === 'YES') {
          const amount = this.fenToYuan(this.temp.rebateAmount)
          this.temp.actualPayAmount = fenToYuan(this.temp.payAmount) - amount
          return this.temp.actualPayAmount
        }
      }
    },
    formatterSumAmount() {
      // isNaN(temp.count !==null?temp.sumAmount = (temp.actualPayAmount * temp.count):temp.sumAmount = temp.actualPayAmount)?0:(temp.count !==null?temp.sumAmount = (temp.actualPayAmount * temp.count):temp.sumAmount = temp.actualPayAmount)
      if (this.valuePass === false) {
        return 0
      } else {
        if (this.temp.count !== null) {
          if (isNaN(this.temp.payamtStr) || this.temp.payamtStr === '') {
            return 0
          } else {
            this.temp.sumAmount = math.format(math.chain(math.bignumber(this.temp.actualPayAmount)).multiply(math.bignumber(this.temp.count)).done())
            return this.temp.sumAmount
          }
        } else {
          if (isNaN(this.temp.payamtStr) || this.temp.payamtStr === '') {
            return 0
          } else {
            this.temp.sumAmount = this.temp.actualPayAmount
            return this.temp.sumAmount
          }
        }
      }
    },
    getProjectIsAllowPayment() {
      getProjectIsAllowPayment(this.$route.query.projectId).then(response => {

      }).catch(() => {
        this.goBack()
        this.btnIsDisabled = true
      })
    },
    getRebateAmountNow() {
      // if (this.temp.payamtStr === '' || isNaN(this.temp.payamtStr)) {
      //   this.temp.payamtStr === ''
      //   this.temp.sumAmount = 0
      //   this.temp.rebateAmount = 0
      // }
      if (this.temp.allowRebate === 'YES') {
        getRebateAmount(this.temp.payamtStr, this.temp.id).then(response => {
          if (response.data) {
            this.temp.rebateAmount = response.data
          } else {
            this.temp.rebateAmount = 0
          }
        }).catch(error => {
          // console.log(error)
          Toast.fail(error.message)
        })
      }
    },
    fenToYuan(val) {
      return fenToYuan(val)
    },
    buyMaxCount() {
      if (this.temp.count >= this.temp.maxCount) {
        Toast.fail('您只可购买' + this.temp.maxCount + '个')
        this.temp.count = this.temp.maxCount
      }
    },
    // 下一步提交
    handlerCreateOrder() {
      this.btnNextLoading = true
      this.temp.schoolCode = window.localStorage.getItem('schoolcode')
      var formAssemblyVoList = []
      // var payUserAssemblyDataVOList = []
      // ####清洗数据#####start
      var otherData = {}
      this.diyFormList.forEach(item => {
        var payProjectFormAssembly = {}
        var payUserAssemblyDataVO = {}
        payProjectFormAssembly.id = item.id
        payUserAssemblyDataVO.id = item.id
        payProjectFormAssembly.assemblyType = item.type
        payProjectFormAssembly.schoolCode = window.localStorage.getItem('schoolcode')
        // payUserAssemblyDataVO.schoolCode = window.localStorage.getItem('schoolcode')
        payProjectFormAssembly.showName = item.label
        payProjectFormAssembly.assemblyKey = item.key
        payProjectFormAssembly.mustStatus = item.pros
        payProjectFormAssembly.tipsInfo = item.placeholder
        payProjectFormAssembly.formAssemblyValue = this.diyTemp[item.key]
        if (item.key === 'NAME') {
          payUserAssemblyDataVO.name = this.diyTemp[item.key]
        } else if (item.key === 'SEX') {
          payUserAssemblyDataVO.sex = this.diyTemp[item.key]
        } else if (item.key === 'IDSERIAL') {
          payUserAssemblyDataVO.idserial = this.diyTemp[item.key]
        } else if (item.key === 'IDNUMBER') {
          payUserAssemblyDataVO.idNumber = this.diyTemp[item.key]
        } else if (item.key === 'PHONE') {
          payUserAssemblyDataVO.phone = this.diyTemp[item.key]
        } else if (item.key === 'EMAIL') {
          payUserAssemblyDataVO.email = this.diyTemp[item.key]
        } else {
          // console.log(item.key + '' + this.diyTemp[item.key])
          this.$set(otherData, item.key, this.diyTemp[item.key])
        }
        this.temp.payUserAssemblyDataVO = payUserAssemblyDataVO
        formAssemblyVoList.push(payProjectFormAssembly)
      })
      otherData = JSON.stringify(otherData)
      this.temp.payUserAssemblyDataVO.otherData = otherData
      // ####清洗数据#####end
      this.temp.formAssemblyVoList = formAssemblyVoList
      createOrder(this.temp).then(response => {
        this.messageCode = response.messageCode
        this.order = response.data
        this.$router.push({ path: '/person', query: { 'projectId': this.$route.query.projectId, 'orderId': this.order.id }})
        this.btnNextLoading = false
      }).catch(error => {
        Toast.fail(error.message)
        this.btnNextLoading = false
      })
    },
    handlerCreateOrderNoForm() {
      this.btnNextLoading = true
      createOrder(this.temp).then(response => {
        this.messageCode = response.messageCode
        this.order = response.data
        this.$router.push({ path: '/person', query: { 'projectId': this.$route.query.projectId, 'orderId': this.order.id }})
        this.btnNextLoading = false
      }).catch(error => {
        Toast.fail(error.message)
        this.btnNextLoading = false
      })
    },
    handlerCreateOrderCommon() {
      this.btnNextLoading = true
      this.temp.schoolCode = window.localStorage.getItem('schoolcode')
      var formAssemblyVoList = []
      // var payUserAssemblyDataVOList = []
      // ####清洗数据#####start
      var otherData = {}
      this.diyFormList.forEach(item => {
        var payProjectFormAssembly = {}
        var payUserAssemblyDataVO = {}
        payProjectFormAssembly.id = item.id
        payUserAssemblyDataVO.id = item.id
        payProjectFormAssembly.assemblyType = item.type
        payProjectFormAssembly.schoolCode = window.localStorage.getItem('schoolcode')
        // payUserAssemblyDataVO.schoolCode = window.localStorage.getItem('schoolcode')
        payProjectFormAssembly.showName = item.label
        payProjectFormAssembly.assemblyKey = item.key
        payProjectFormAssembly.mustStatus = item.pros
        payProjectFormAssembly.tipsInfo = item.placeholder
        payProjectFormAssembly.formAssemblyValue = this.diyTemp[item.key]
        if (item.key === 'NAME') {
          payUserAssemblyDataVO.name = this.diyTemp[item.key]
        } else if (item.key === 'SEX') {
          payUserAssemblyDataVO.sex = this.diyTemp[item.key]
        } else if (item.key === 'IDSERIAL') {
          payUserAssemblyDataVO.idserial = this.diyTemp[item.key]
        } else if (item.key === 'IDNUMBER') {
          payUserAssemblyDataVO.idNumber = this.diyTemp[item.key]
        } else if (item.key === 'PHONE') {
          payUserAssemblyDataVO.phone = this.diyTemp[item.key]
        } else if (item.key === 'EMAIL') {
          payUserAssemblyDataVO.email = this.diyTemp[item.key]
        } else {
          console.log(item.key + '' + this.diyTemp[item.key])
          this.$set(otherData, item.key, this.diyTemp[item.key])
        }
        this.temp.payUserAssemblyDataVO = payUserAssemblyDataVO
        formAssemblyVoList.push(payProjectFormAssembly)
      })
      otherData = JSON.stringify(otherData)
      this.temp.payUserAssemblyDataVO.otherData = otherData
      // ####清洗数据#####end
      this.temp.formAssemblyVoList = formAssemblyVoList
      createCommonOrder(this.temp).then(response => {
        this.messageCode = response.messageCode
        this.order = response.data
        this.$router.push({ path: '/person', query: { 'projectId': this.$route.query.projectId, 'orderId': this.order.id }})
        this.btnNextLoading = false
      }).catch(error => {
        Toast.fail(error.message)
        this.btnNextLoading = false
      })
    },
    handlerCreateOrderNoFormCommon() {
      this.btnNextLoading = true
      createCommonOrder(this.temp).then(response => {
        this.messageCode = response.messageCode
        this.order = response.data
        this.$router.push({ path: '/person', query: { 'projectId': this.$route.query.projectId, 'orderId': this.order.id }})
        this.btnNextLoading = false
      }).catch(error => {
        Toast.fail(error.message)
        this.btnNextLoading = false
      })
    },
    submit() {
      // var a = JSON.stringify(this.diyFormList)
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.saveLoading = true
          this.temp.schoolCode = window.localStorage.getItem('schoolcode')
          var formAssemblyVoList = []
          // ####清洗数据#####start
          this.diyFormList.forEach(item => {
            var payProjectFormAssembly = {}
            payProjectFormAssembly.assemblyType = item.type
            payProjectFormAssembly.schoolCode = window.localStorage.getItem('schoolcode')
            payProjectFormAssembly.showName = item.label
            payProjectFormAssembly.assemblyKey = item.key
            payProjectFormAssembly.mustStatus = item.pros
            payProjectFormAssembly.tipsInfo = item.placeholder
            if (item.type === 'select') {
              var listAssemblyDetail = []
              item.options.forEach(obj => {
                var payProjectAssemblyDetail = {}
                payProjectAssemblyDetail.schoolCode = window.localStorage.getItem('schoolcode')
                payProjectAssemblyDetail.showDetail = obj.label
                payProjectAssemblyDetail.detailValue = obj.key
                listAssemblyDetail.push(payProjectAssemblyDetail)
              })
            }
            payProjectFormAssembly.listAssemblyDetail = listAssemblyDetail
            formAssemblyVoList.push(payProjectFormAssembly)
          })
          // ####清洗数据#####end
          this.temp.formAssemblyVoList = formAssemblyVoList
          this.$emit('submit', this.temp)
          this.saveLoading = false
        }
      })
    },
    addOptions() {
      var obj = {}
      obj.label = ''
      this.configTemp.options.push(obj)
    },
    choose(value) {
      this.money = value
    },
    onConfirm(value) {
      // console.log(value)
      this.diyTemp[this.pickerList.key] = value
      this.pickerList.showPicker = false
      // for (let i = 0; i < this.pickerList.options.length; i++) {
      //   if (this.pickerList[i].options.label === value) {
      //     value = this.pickerList[i].options.key
      //   }
      // }
      // this.pickerList
      // this.diyTemp[value.key] = value
      // console.log(this.diyTemp[value.key])
      // value.showPicker = false
      // return value
    },
    pickerClick(item) {
      this.pickerList = {}
      // console.log(item)
      this.pickerList = item
      item.showPicker = true
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/home' })
    },
    // 获取数据
    getList() {
      this.hidebox = false
      this.loading = true
      this.divdas = false
      this.showvacuous = false
      this.pageCurrent = 1
      this.conheight.background = 'transparent'
      setTimeout(() => {
        pageListReiForm(
          this.pageCurrent,
          this.page,
          this.summary,
          this.formStatus
        ).then(response => {
          this.list = response.data.records
          this.loading = false
          this.conheight.background = '#EFF6FF'
          if (this.list.length === 0) {
            this.showvacuous = true
          } else {
            for (var i = 0; i < this.list.length; i++) {
              this.list[i].currentStepLimit = moment(
                this.list[i].currentStepLimit
              ).format('YYYY/MM/DD HH:mm:ss')
              const numm =
                (Date.parse(this.nowdata) -
                  Date.parse(this.list[i].currentStepLimit.slice(0, 10))) /
                  1000 /
                  3600 /
                  24 +
                ''
              this.numlist.push(parseInt(numm))
            }
            for (let i = 0; i < this.list.length; i++) {
              const titleString = this.list[i].summary
              if (!titleString) {
                return ''
              }
              if (this.summary && this.summary.length > 0) {
                // 匹配关键字正则
                const replaceReg = new RegExp(this.summary, 'g')
                // 高亮替换v-html值
                const replaceString =
                  '<pre class="search-text">' + this.summary + '</pre>'
                // 开始替换
                this.list[i].summary = this.list[i].summary.replace(
                  replaceReg,
                  replaceString
                )
              }
            }
          }
          this.hidebox = true
        })
      }, 1000)
    },
    // 获取用户信息
    getUserInfo() {
      var msg = JSON.parse(localStorage.getItem('UserMsg'))
      this.userName = msg.name
    },
    // 带参数跳转页面
    onSubmit() {
      if (this.diyFormShowFlag === true) {
        this.handlerCreateOrderCommon()
      } else {
        this.handlerCreateOrderNoFormCommon()
      }
    }
    // 带参数跳转页面
    // onSubmit(item) {
    //   this.btnNextLoading = true
    //   this.temp.schoolCode = window.localStorage.getItem('schoolcode')
    //   var formAssemblyVoList = []
    //   // var payUserAssemblyDataVOList = []
    //   // ####清洗数据#####start
    //   var otherData = {}
    //   this.diyFormList.forEach(item => {
    //     var payProjectFormAssembly = {}
    //     var payUserAssemblyDataVO = {}
    //     payProjectFormAssembly.id = item.id
    //     payUserAssemblyDataVO.id = item.id
    //     payProjectFormAssembly.assemblyType = item.type
    //     payProjectFormAssembly.schoolCode = window.localStorage.getItem('schoolcode')
    //     // payUserAssemblyDataVO.schoolCode = window.localStorage.getItem('schoolcode')
    //     payProjectFormAssembly.showName = item.label
    //     payProjectFormAssembly.assemblyKey = item.key
    //     payProjectFormAssembly.mustStatus = item.pros
    //     payProjectFormAssembly.tipsInfo = item.placeholder
    //     payProjectFormAssembly.formAssemblyValue = this.diyTemp[item.key]
    //     if (item.key === 'NAME') {
    //       payUserAssemblyDataVO.name = this.diyTemp[item.key]
    //     } else if (item.key === 'SEX') {
    //       payUserAssemblyDataVO.sex = this.diyTemp[item.key]
    //     } else if (item.key === 'IDSERIAL') {
    //       payUserAssemblyDataVO.idserial = this.diyTemp[item.key]
    //     } else if (item.key === 'IDNUMBER') {
    //       payUserAssemblyDataVO.idNumber = this.diyTemp[item.key]
    //     } else if (item.key === 'PHONE') {
    //       payUserAssemblyDataVO.phone = this.diyTemp[item.key]
    //     } else if (item.key === 'EMAIL') {
    //       payUserAssemblyDataVO.email = this.diyTemp[item.key]
    //     } else {
    //       console.log(item.key + '' + this.diyTemp[item.key])
    //       this.$set(otherData, item.key, this.diyTemp[item.key])
    //     }
    //     this.temp.payUserAssemblyDataVO = payUserAssemblyDataVO
    //     formAssemblyVoList.push(payProjectFormAssembly)
    //   })
    //   otherData = JSON.stringify(otherData)
    //   this.temp.payUserAssemblyDataVO.otherData = otherData
    //   // ####清洗数据#####end
    //   this.temp.formAssemblyVoList = formAssemblyVoList
    //   createOrder(this.temp).then(response => {
    //     this.messageCode = response.messageCode
    //     this.order = response.data
    //     this.$router.push({ path: 'person', query: { 'projectId': this.$route.query.projectId, 'orderId': this.order.id }})
    //     this.btnNextLoading = false
    //   }).catch(() => {
    //     this.btnNextLoading = false
    //   })
    // }
  }
}
</script>
<style scoped lang="scss">
.red{
    .van-cell__value{
        color:red;
        span{
            color: red;
        }
    }
     .van-field__control--right{
        color:red
    }
}
#commonPay {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  padding: 10px;
  margin: 10px 15px 10px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.boxstylemoney{
  margin: 10px 15px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 20px;
  }
}
.lastbattry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: #1989fa;
    float: right;
  }
}
.paymonry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: red;
    float: right;
  }
}
.picker{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 10px 20px;
  width: 90%;
  padding: 5px;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80px;
  z-index: 99;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__title {
    span {
      height: 44px;
      line-height: 44px;
    }
}
.boxstyle .van-cell {
    padding: 18px 20px;
}
.boxstylemoney {
  .van-cell {
      padding: 18px 20px !important;
  }
}
</style>
<style lang="scss">
#commonPay{
    .van-field__control--right{
        color:red
    }
}
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
/* .van-cell {
    border: 1px solid #eee;
    border-radius: 5px;
    margin: 10px 20px;
    width: 90%;
} */
/* .van-field__control {
    height: 15px;
} */
.van-nav-bar {
  height: 80px;
}
#commonPay .van-field__label {
    line-height: 24px !important;
    height: 24px !important;
    width: 3.4rem;
}
#commonPay .van-field__value {
    line-height: 24px !important;
    height: 24px !important;
}
/* #commonPay .van-field__control--custom {
    margin-top: -10px;
} */
</style>
